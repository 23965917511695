import { Center, Spinner } from "@chakra-ui/react";
import { Create, Home as HomeIcon, People } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { createContext } from "react";
import { Navigate, Route, Routes } from "react-router";

import { LinkItemProps } from "../library/ui_custom/sidebar";
import CreateActionItem from "./components/action_items/create_action_item";
import RetroSpaceTemplate from "./components/retro_space_template";
import Home from "./home";
import ActivelyDatingOnboardingScreen from "./home/actively_dating_onboarding_screen";
import CommittedRelationshipOnboardingScreen from "./home/committed_relationship_onboarding_screen";
import StatusSelectionScreen from "./home/status_selection_screen";
import UserAppLayout from "./layout";
import Memo from "./memo";
import CreateRetroSpace from "./retro_space/create_retro_space";
import Retro from "./retros_page";
import CreateRetro from "./retros_page/create_retro";

type UserContextType = {
  userId: number;
  firstName: string;
  lastName: string;
  email: string;
  relationshipStatus: string;
  retroSpaces: Array<{
    id: number;
    name: string;
  }>;
};

export const UserContext = createContext<UserContextType | undefined>(
  undefined
);

function App() {
  const { isLoading: userContextIsLoading, data: userContextData } = useQuery({
    queryKey: ["user"],
    queryFn: (): Promise<UserContextType> =>
      axios.get("/api/user_app/profile").then((res) => res.data),
  });

  let linkItems: Array<LinkItemProps> = [];

  if (userContextData) {
    linkItems = [
      { name: "Home", icon: HomeIcon, path: "home" },
      ...userContextData.retroSpaces.map((retroSpace) => ({
        name: retroSpace.name,
        icon: People,
        path: `retro_spaces/${retroSpace.id}`,
      })),
      {
        name: "Memos to Self",
        icon: Create,
        path: "memo",
      },
    ];
  }

  const onLogout = () => {
    axios.delete("/users/sign_out").then(() => {
      window.location.reload();
    });
  };

  return (
    <>
      {userContextIsLoading ? (
        <Center h="100vh">
          <Spinner size="xl" />
        </Center>
      ) : (
        <UserContext.Provider value={userContextData}>
          <Routes>
            <Route
              element={
                <UserAppLayout linkItems={linkItems} onLogout={onLogout} />
              }
            >
              <Route index element={<Navigate to="home" replace />} />
              <Route
                path="relationship_status"
                element={<StatusSelectionScreen />}
              />
              <Route
                path="onboarding/dating"
                element={<ActivelyDatingOnboardingScreen />}
              />
              <Route
                path="onboarding/relationship"
                element={<CommittedRelationshipOnboardingScreen />}
              />
              <Route path="home" element={<Home />} />
              <Route path="retro_spaces/new" element={<CreateRetroSpace />} />

              <Route path="retro_spaces/:retro_space_id">
                <Route index={true} element={<RetroSpaceTemplate />} />
                <Route
                  index={false}
                  path="retros/:retro_id"
                  element={<Retro />}
                />
                <Route
                  index={false}
                  path="retros/new_retro"
                  element={<CreateRetro />}
                />
                <Route
                  index={false}
                  path="retros/:retro_id/new_action_item"
                  element={<CreateActionItem />}
                />
              </Route>

              <Route path="memo" element={<Memo />}></Route>
            </Route>
          </Routes>
        </UserContext.Provider>
      )}
    </>
  );
}

export default App;
