import {
  Button,
  Card,
  CardBody,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  chakra,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import React from "react";
import { useForm } from "react-hook-form";

import StyledAsterisk from "../../library/ui_custom/styled_asterisk";
import Form from "../../library/ui_primitives/form";

type FormValues = {
  name: string;
};

const StyledDescription = chakra("p", {
  baseStyle: {
    marginTop: "2em",
    marginBottom: "2em",
  },
});

const StyledButton = chakra(Button, {
  baseStyle: {
    marginTop: "3em",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
});

const StyledCard = chakra(Card, {
  baseStyle: {
    backgroundColor: "core.apple",
  },
});

export default function CreateRetroSpace() {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
  } = useForm<FormValues>();

  const mutation = useMutation(async (data: FormValues) => {
    const res = await axios.post("/api/user_app/retro_spaces/create", {
      ...data,
    });

    if (res.data.retroSpaceId) {
      window.location.assign(`/user_app/retro_spaces/${res.data.retroSpaceId}`);
    } else {
      setError("name", {
        type: "server",
        message: res.data.error,
      });
    }
  });

  return (
    <div>
      <Heading as="h1" textAlign="center">
        Build your hive
      </Heading>
      <StyledDescription>
        <strong>What is a hive?</strong> This will be the space that's
        designated for you and your partner to regularly add your recaps about
        your relationship so far. Additionally, you will have access to a
        private space where you can write memos and, if desired, share them with
        your partner.
      </StyledDescription>

      <StyledDescription>
        {" "}
        To get started, set a name for your Hive.
      </StyledDescription>

      <StyledCard>
        <CardBody>
          <Form onSubmit={handleSubmit((data) => mutation.mutateAsync(data))}>
            <FormControl isRequired isInvalid={!!errors.name}>
              <FormLabel htmlFor="name" requiredIndicator={<StyledAsterisk />}>
                Hive Name
              </FormLabel>
              <Input
                id="name"
                type="text"
                borderColor="gray.400"
                {...register("name", {
                  required: {
                    value: true,
                    message: "Error: Name for your hive is required",
                  },
                })}
              />
              <FormErrorMessage>
                {errors.name && errors.name.message?.toString()}
              </FormErrorMessage>
            </FormControl>

            <StyledButton isLoading={isSubmitting} type="submit">
              Submit
            </StyledButton>
          </Form>
        </CardBody>
      </StyledCard>
    </div>
  );
}
