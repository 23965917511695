import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Spinner,
  Tag,
  chakra,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";

import { FormValues } from ".";
import { textWithoutHtmlTags } from "../../../utility/string_utils";
import RichTextArea from "../../library/ui_custom/rich_text_area";
import StyledAsterisk from "../../library/ui_custom/styled_asterisk";
import WarningNotificationBanner from "../../library/ui_custom/warning_notification_banner";
import Form from "../../library/ui_primitives/form";
import Input from "../../library/ui_primitives/input";
import Summarization from "../summarization";
import { MemoEntry } from "./types";

const StyledFormControl = chakra(FormControl, {
  baseStyle: {
    marginBottom: "2em",
  },
});

// The presence of the modal will be driven by: if `memo_entry_id` search param exists.
export default function MemoEntryEditModal({
  canUseAiFeatures,
}: {
  canUseAiFeatures: boolean;
}) {
  const toast = useToast();
  const { isOpen, onOpen, onClose: onDisclosureClose } = useDisclosure();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const [isEmptyContent, setIsEmptyContent] = React.useState(false);

  const memoEntryId = searchParams.get("memo_entry_id");

  useEffect(() => {
    if (memoEntryId) {
      onOpen();
    }
  }, [memoEntryId]);

  const { isLoading: memoEntryIsLoading, data: memoEntryData } = useQuery({
    queryKey: ["memoEntry", memoEntryId],
    queryFn: (): Promise<MemoEntry> =>
      axios
        .get(`/api/user_app/memo_entries/${memoEntryId}`)
        .then((res) => res.data),
    enabled: !!memoEntryId, // Only run this query if memoEntryId exists.
  });

  const summaryText = memoEntryData?.summaryText;
  const isSummaryStale = memoEntryData?.isSummaryStale;

  const {
    register,
    reset,
    handleSubmit,
    control,
    formState: { errors, isSubmitting, isDirty },
  } = useForm<FormValues>();

  // Since memoEntryData is initially null when the modal loads,
  // reset the default values when it does load.
  // This ensures the form is populated with the correct data when the modal opens.
  useEffect(() => {
    reset(memoEntryData);
  }, [memoEntryData]);

  const { mutateAsync: updateMemoEntry } = useMutation({
    mutationFn: async (data: FormValues) => {
      const res = await axios.put(`/api/user_app/memo_entries/${memoEntryId}`, {
        ...data,
        memoEntryId: memoEntryId,
      });

      if (res.data.memoEntryId) {
        toast({
          title: "Success: Memo updated! 🎉",
          status: "success",
        });
        setSearchParams({});
        onDisclosureClose();
      } else {
        toast({
          title: res.data.errors.join(","),
          status: "error",
        });
      }
    },
    onSuccess: () => {
      // Refetch the memo entry to get the updated summary.
      queryClient.invalidateQueries(["memoEntry", memoEntryId]);
      // Refetch the index memo entries.
      queryClient.invalidateQueries(["memoEntries"]);
    },
  });

  const { mutateAsync: createSummary, isLoading: createSummaryLoading } =
    useMutation({
      mutationFn: async () => {
        try {
          await axios.post("/api/user_app/summarizations/create", {
            inputText: memoEntryData?.content || "",
            memoEntryId: memoEntryId,
          });
        } catch (error) {
          console.error("Error summarizing text:", error);
        }
      },
      onSuccess: () => {
        // Refetch the memo entry to get the updated summary.
        queryClient.invalidateQueries(["memoEntry", memoEntryId]);
        // Refetch the index memo entries.
        queryClient.invalidateQueries(["memoEntries"]);
      },
    });

  const finishedLoading =
    !!memoEntryId && !!memoEntryData && !memoEntryIsLoading;

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setSearchParams({});
        onDisclosureClose();
      }}
      size="xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>View/Edit Memo</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Skeleton isLoaded={finishedLoading}>
            <Form onSubmit={handleSubmit((data) => updateMemoEntry(data))}>
              <StyledFormControl>
                <FormLabel htmlFor="memoEntryTitleInEditView">
                  Memo Title
                </FormLabel>
                <Input
                  {...register("title")}
                  id="memoEntryTitleInEditView"
                  type="text"
                />
                <FormErrorMessage>
                  {errors.title && errors.title.message?.toString()}
                </FormErrorMessage>
              </StyledFormControl>

              <Box display="flex" flexDirection="column" gap="1em">
                {createSummaryLoading ? (
                  <Spinner size="md" label="summary is generating" />
                ) : summaryText ? (
                  <Summarization summaryText={summaryText} />
                ) : (
                  <></>
                )}
                {isSummaryStale && (
                  <Button
                    marginBottom="2em"
                    data-umami-event="view-memo-summary-btn"
                    onClick={() => {
                      createSummary();
                    }}
                    variant="outline"
                    disabled={createSummaryLoading || !canUseAiFeatures}
                  >
                    Update AI Summary
                  </Button>
                )}

                {!canUseAiFeatures && (
                  <WarningNotificationBanner
                    warningText={
                      "Buzz buzz! You've reached your limit to access our summarizer. Don’t be(e) discouraged—if you want to continue seeing new summaries, email us at help@claribee.com."
                    }
                  />
                )}
              </Box>

              <StyledFormControl isRequired isInvalid={!!errors.content}>
                <FormLabel
                  htmlFor="memoEntryContentInEditView"
                  requiredIndicator={<StyledAsterisk />}
                >
                  Add your thoughts...
                </FormLabel>

                <Controller
                  control={control}
                  name="content"
                  rules={{
                    required: true,
                  }}
                  defaultValue={""}
                  render={({ field, fieldState }) => {
                    return (
                      <RichTextArea
                        editable={true}
                        initialEditableContainer={false}
                        value={field.value}
                        onChange={(
                          e: React.ChangeEvent<HTMLTextAreaElement>
                        ) => {
                          field.onChange(e);
                          const isEmptyContent =
                            textWithoutHtmlTags(e.toString() || "").length ===
                            0;
                          setIsEmptyContent(isEmptyContent);
                        }}
                        isDirty={fieldState.isDirty}
                      />
                    );
                  }}
                />
                <FormErrorMessage>
                  {errors.content && (
                    <p>
                      Error: Please add some thoughts above in order to save
                      your entry.
                    </p>
                  )}
                </FormErrorMessage>
              </StyledFormControl>

              {memoEntryData?.actionItems.map((actionItem, index) => {
                return (
                  <StyledFormControl key={index}>
                    <Controller
                      name={`actionItems.${index}`}
                      control={control}
                      render={({ field: { value, onChange, ...rest } }) => {
                        return (
                          value && (
                            <Box display="flex" gap="1em" alignItems="center">
                              {value.isComplete && (
                                <Tag size="md" colorScheme="green">
                                  Completed
                                </Tag>
                              )}
                              {!value.isComplete && (
                                <Tag size="md" colorScheme="red">
                                  Incomplete
                                </Tag>
                              )}

                              <Box flexGrow="1">
                                <FormLabel htmlFor={`actionItem${index}`}>
                                  Action Item #{index + 1} (optional)
                                </FormLabel>
                                <Input
                                  id={`actionItem${index}`}
                                  type="text"
                                  value={value.description}
                                  isDisabled={value.isComplete ? true : false}
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) =>
                                    onChange({
                                      ...value,
                                      description: e.target.value,
                                    })
                                  }
                                  {...rest}
                                />
                              </Box>
                            </Box>
                          )
                        );
                      }}
                    />
                  </StyledFormControl>
                );
              })}

              <ModalFooter>
                <Box marginTop="-2em">
                  <Button
                    mr={3}
                    type="submit"
                    isLoading={isSubmitting}
                    isDisabled={!isDirty || isEmptyContent}
                  >
                    Save
                  </Button>
                  <Button
                    variant="ghost"
                    onClick={() => {
                      setSearchParams({});
                      onDisclosureClose();
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              </ModalFooter>
            </Form>
          </Skeleton>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
