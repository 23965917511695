import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Skeleton,
  chakra,
} from "@chakra-ui/react";
import { Notes, People } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router";

import { UserContext } from "../app";
import ActionItemDeleteConfirmationModal from "../components/action_items/action_item_delete_confirmation_modal";
import ActionItemMobileCard from "../components/action_items/action_item_mobile_card";
import ActionItemTable, {
  ActionItemType,
} from "../components/action_items/action_item_table";
import EditActionItemModal from "../components/action_items/edit_action_item_modal";
import { useIsMobile } from "../hooks/use_is_mobile";

const StyledPathsContainer = chakra(Box, {
  baseStyle: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: "2em",
    "@media (max-width: 900px)": {
      flexDirection: "column",
      marginTop: "0em",
    },
  },
});

const StyledPathRoute = chakra(Box, {
  baseStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "@media (max-width: 900px)": {
      marginBottom: "2em",
    },
  },
});

const StyledRemainingActionItemsContainer = chakra(Box, {
  baseStyle: {
    marginTop: "4em",
  },
});

// The following styled components are replaced with Chakra UI's chakra function because the components are using Chakra UI components
const StyledCard = chakra(Card, {
  baseStyle: {
    marginTop: "4em",
    marginBottom: "2em",
    backgroundColor: "core.apple",
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
  },
});

const StyledPathsButton = chakra(Button, {
  baseStyle: {
    backgroundColor: "darkMoss.800",
    marginTop: "0.5em",
  },
});

const StyledRetroSpaceButton = chakra(Button, {
  baseStyle: {
    padding: "3em",
    margin: "0.5em",
  },
});

const StyledCreateNewRetroSpaceButton = chakra(Button, {
  baseStyle: {
    textWrap: "wrap",
  },
});

const StyledHeading = chakra(Heading, {
  baseStyle: {
    marginBottom: "2em",
    fontWeight: "bold",
  },
});

function Home() {
  const userProfile = useContext(UserContext);
  const isMobile = useIsMobile();

  let navigate = useNavigate();
  const [deleteActionItemConfirmationId, setDeleteActionItemConfirmationId] =
    useState<number | undefined>(undefined);

  const navigateToCreateRetroSpace = () => {
    let path = `/user_app/retro_spaces/new`;
    navigate(path);
  };

  const navigateToRetroSpace = (retroSpaceId: number) => {
    let path = `/user_app/retro_spaces/${retroSpaceId}`;
    navigate(path);
  };

  const navigateToMemo = () => {
    let path = `/user_app/memo`;
    navigate(path);
  };

  const {
    isLoading: actionItemsLoading,
    data: actionItemsData,
    refetch,
  } = useQuery({
    queryKey: ["actionItems"],
    queryFn: (): Promise<{
      actionItems: ActionItemType[];
    }> => axios.get(`/api/user_app/action_items`).then((res) => res.data),
  });

  const actionItems = actionItemsData?.actionItems;

  return (
    <>
      <EditActionItemModal
        onSaveComplete={() => {
          refetch();
        }}
      />
      <ActionItemDeleteConfirmationModal
        actionItemId={deleteActionItemConfirmationId}
        onCloseComplete={() => setDeleteActionItemConfirmationId(undefined)}
        onDeleteComplete={() => {
          refetch();
        }}
      />
      <Box
        display="flex"
        justifyContent="space-between"
        marginBottom="3em"
        alignItems={"center"}
      >
        <Heading as="h1" size="md">
          {userProfile?.retroSpaces.length === 0 ? "Welcome" : "Welcome back"},{" "}
          {userProfile?.firstName}!
        </Heading>
        <StyledCreateNewRetroSpaceButton
          leftIcon={<People />}
          onClick={navigateToCreateRetroSpace}
          data-umami-event="build-new-hive-btn"
        >
          Build hive
        </StyledCreateNewRetroSpaceButton>
      </Box>
      <div>
        {userProfile?.retroSpaces.length === 0 && (
          <div>
            {actionItems && actionItems.length > 0 && (
              <StyledRemainingActionItemsContainer>
                <StyledHeading as="h2">
                  Your Remaining Action Items
                </StyledHeading>
                <Skeleton isLoaded={!actionItemsLoading}>
                  <ActionItemTable
                    actionItems={actionItems}
                    onDeleteClicked={(id) => {
                      setDeleteActionItemConfirmationId(id);
                    }}
                    refetch={refetch}
                  />
                </Skeleton>
              </StyledRemainingActionItemsContainer>
            )}

            <StyledCard>
              <CardHeader textAlign="center">
                <Heading size="sm">Where would you like to go next?</Heading>
              </CardHeader>
              <CardBody>
                <StyledPathsContainer>
                  {userProfile?.relationshipStatus ===
                    "COMMITTED_RELATIONSHIP" && (
                    <StyledPathRoute>
                      <p>My partner and I are ready to try this out!</p>
                      <StyledPathsButton
                        leftIcon={<People />}
                        onClick={navigateToCreateRetroSpace}
                        data-umami-event="build-new-hive-btn"
                      >
                        Build your hive
                      </StyledPathsButton>
                    </StyledPathRoute>
                  )}

                  {userProfile?.relationshipStatus === "ACTIVELY_DATING" && (
                    <StyledPathRoute>
                      <p>Start organizing my dating experiences</p>
                      <StyledPathsButton
                        leftIcon={<People />}
                        onClick={() => {}}
                        data-umami-event="begin-tracking-dates-btn"
                      >
                        Begin organizing
                      </StyledPathsButton>
                    </StyledPathRoute>
                  )}

                  <StyledPathRoute>
                    <p>I want to do some self-exploration</p>
                    <StyledPathsButton
                      leftIcon={<Notes />}
                      onClick={navigateToMemo}
                      data-umami-event="visit-memo-btn"
                    >
                      Visit my private memos
                    </StyledPathsButton>
                  </StyledPathRoute>
                </StyledPathsContainer>
              </CardBody>
            </StyledCard>

            {/* // TODO: Re-comment back in post-BETA
            <Heading as="h2" size="md">
              Quick Tour of the Hive
            </Heading> */}
            {/* // TODO: Add a video or carousal of images to show the user how to use the app */}
          </div>
        )}
      </div>

      <div>
        {userProfile?.retroSpaces && userProfile?.retroSpaces.length > 0 && (
          <>
            {userProfile?.retroSpaces.map((retroSpace) => (
              <StyledRetroSpaceButton
                key={retroSpace.id}
                onClick={() => navigateToRetroSpace(retroSpace.id)}
                data-umami-event="visit-most-recent-recap-btn"
              >
                Most Recent Recap For: <br></br> {retroSpace.name}
              </StyledRetroSpaceButton>
            ))}
            {actionItems && actionItems.length > 0 && (
              <StyledRemainingActionItemsContainer>
                <StyledHeading as="h2">
                  Your Remaining Action Items
                </StyledHeading>
                <Skeleton isLoaded={!actionItemsLoading}>
                  {isMobile ? (
                    <ActionItemMobileCard
                      actionItems={actionItems}
                      onDeleteClicked={(id) => {
                        setDeleteActionItemConfirmationId(id);
                      }}
                      refetch={refetch}
                    />
                  ) : (
                    <ActionItemTable
                      actionItems={actionItems}
                      onDeleteClicked={(id) => {
                        setDeleteActionItemConfirmationId(id);
                      }}
                      refetch={refetch}
                    />
                  )}
                </Skeleton>
              </StyledRemainingActionItemsContainer>
            )}
          </>
        )}
      </div>
    </>
  );
}
export default Home;
