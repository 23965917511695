import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import React, { useEffect } from "react";

export default function MemoEntryDeleteConfirmationModal({
  memoEntryId,
  onCloseComplete,
}: {
  memoEntryId: number | undefined;
  onCloseComplete: () => void;
}) {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { isOpen, onOpen, onClose: onDisclosureClose } = useDisclosure();
  useEffect(() => {
    if (memoEntryId) {
      onOpen();
    }
  }, [memoEntryId]);

  const { mutateAsync: deleteMemoEntry } = useMutation({
    mutationFn: async (memoEntryId: number) => {
      await axios.delete(`/api/user_app/memo_entries/${memoEntryId}`);
    },
    onSuccess: () => {
      // Refetch the memo entries to update the UI.
      queryClient.invalidateQueries(["memoEntries"]);
      toast({
        title: "Success: Memo has been deleted. 🎉",
        status: "success",
      });
    },
    onError: () => {
      toast({
        title: "An error occurred.",
        description: "Please try again later.",
        status: "error",
      });
    },
  });

  if (!memoEntryId) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onDisclosureClose}
      onCloseComplete={onCloseComplete}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Please Confirm</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <p>Are you sure you want to delete this memo?</p>
          <ModalFooter>
            <Button
              mr={3}
              onClick={() => {
                deleteMemoEntry(memoEntryId);
                onDisclosureClose();
              }}
            >
              Yes, delete
            </Button>
            <Button
              variant="ghost"
              onClick={() => {
                onDisclosureClose();
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
